



// import React, { useState, useEffect } from 'react';
// import { Link, useParams, useLocation } from 'react-router-dom';
// import user from "../../../../assets/user.png";
// import back from "../../../../assets/back.png";
// import { ProficiencyView ,AcademicYear ,PubliCationTableData} from '../../../../ApiServices';
// import "../../Employees/ViewProficiancy/ViewProficiancy.css";
// import YetToSubmit from "../../../../assets/yet to submit.png"
// import verfied from "../../../../assets/verified.png"
// import rejected from "../../../../assets/Icon ionic-ios-close-circle.png"
// import pending from "../../../../assets/Icon ionic-ios-alert.png"
// import { TbAwardFilled } from "react-icons/tb";
// import fileLogo from "../../../../assets/file/file.png"
// import awardLogo from "../../../../assets/award/award.png"
// import book from "../../../../assets/book/book.png"
// import resp from "../../../../assets/resp/resp.png"
// import CourseTable from '../../../../components/CourseDetailTable/CourseTable';


// export const ViewProficiancy = () => {
//   const [noImage, setNoImage] = useState(true);
//   const [profileImage, setProfileImage] = useState("")
//   const [data, setData] = useState({});
//   const [Verified,setVerified] = useState([])
//   const [academicYr ,setAcademicYr]=useState("")
//   const [academicYears,setAcademicYear] = useState([]);
//   const [selectedItem , setSelectedItem] = useState()
//   const [view , setView] = useState(false)
//   const [passingData , SetPassingData ] = useState()
//   const imageUrl = "";
//   const { id } = useParams();
//   console.log(id, "am the idddddddddd");
//   const location = useLocation();
//   // const employeeData = location.state?.employeeData ;
//   // console.log( location.state?.image, "locationImgae")
//   // const image = location.state?.image
//   const { employeeData, imagePath } = location.state || {};

//   useEffect(()=>{
//   console.log(passingData , "the data am passing ")
//   },[passingData])
 
//   const fetchData = async () => {
//     const imageUrls = "https://dev.vinbytes.com/kings-faculty/employee/get/uploaded/file?fileName=" + imagePath;
//     try {
//       const response = await fetch(imageUrls)
//       if (response.ok) {
//         setProfileImage(imageUrls)
//       }
//     } catch (error) {
//       console.error("Error making HTTP call:", error);
//     }
//   }

//   const acad = ()=>{
//     AcademicYear()
//     .then((res:any) =>{
//       console.log(res.data.data);
//       const uniqueAcademicYears = res.data.data.filter((item:any, index:any, self:any) =>
//         index === self.findIndex((t:any) => (
//           t.academicYear === item.academicYear
//         ))
//       );
//       setAcademicYear(uniqueAcademicYears)
//     }) 
//   }
//   const academicYearFromDb = academicYears;
//   useEffect(()=>{
//     acad()
//     },[])

  
//   useEffect(() => {
//     const data ={ 
//       id:id,
//       academicYear:academicYr
//     }
//     console.log(data , "dataa")
//     ProficiencyView(data)
//       .then((res) => {
//         console.log(res.data.data[0], "viewProficiency");
//         setSelectedItem(res.data.data[0])
//         setVerified(res.data.data)
//         setData(res.data.data[0]);
//       }).catch((err) => {
//         console.log(err);
//       });
//       fetchData()
//   }, [id , academicYr]);

//   const formatCamelCase = (str: string) => {
//     return str
//       .replace(/([a-z])([A-Z])/g, '$1 $2') 
//       .replace(/^./, str[0].toUpperCase()); 
//   };

//   const viewSpecificProficiency =(item:any)=>{
  
//     navigateView(item)
//    console.log(item , " passingData")
//   }

//   function navigateView(item: any){
//     PubliCationTableData(item).then((res:any)=>{
//       SetPassingData(res.data.data);
//       console.log(res.data.data, "dataaa")
//       // setViewTitle(res.data.data[0].proficiency.title)
//       // setPubIdView(res.data.data[0].proficiency.pubId)
//       // setPubImageUrl(res.data.data[0].proficiency.publicationPhoto)
//       // setViewSingle(true) 
//       setView(true)
      
//       const formattedCreatedDate = new Date(res.data.data[0].createdOn);
//       // const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");

//       let updatedOn = "";
//       if (res.data.data[0].updatedOn !== null) {
//           const formattedUpdatedDate = new Date(res.data.data[0].updatedOn);
//           // updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
//       }        
//       // setCreatedOn(createdOn);  
//       // setUpdatedOn(updatedOn);
//     }).catch((err:any)=>console.log(err))
//   }


//   const renderArrayItems = (arrayName: any, items: any) => {
//     console.log(items, "logg")
//     return (
//       <>
//     <div key={arrayName}>   
//     <div className='d-flex align-items-center justify-content-between divHead'>
//     <div className='mainheader mb-2'>
//        {/* {items.length > 0 ? arrayName.charAt(0).toUpperCase() + arrayName.slice(1) : ""} */}
//        {items.length > 0 ? formatCamelCase(arrayName) : ""}
//      </div>
//     {items.length > 0 ? 
//      <div>
//      <span className='noOfVerified'>
//        {/* 1 of 2 verified */}
//        {Verified.map((item:any)=>(
//         <>
//          {/* {arrayName === 'publications' && item.pubVerifiedCounts && item.pubCount  ? `${item.pubVerifiedCounts} out of ${item.pubCount} verified` : ''}  */}
//          {arrayName === 'publications' ? `${item.pubVerifiedCounts} out of ${item.pubCount} verified` : ''}
//          {arrayName === 'patents' ? `${item.patVerifiedCounts} out of ${item.patCount} verified` : ''}
//          {arrayName === 'honoursAndAwards' ? `${item.honVerifiedCounts} out of ${item.honCount} verified` : ''}
//          {arrayName === 'trainings' ? `${item.trainVerifiedCounts} out of ${item.trainCount} verified` : ''}
//          {arrayName === 'guidance' ? `${item.guidVerifiedCounts} out of ${item.guidCount} verified` : ''}
//          {arrayName === 'sponsoredResearch' ? `${item.spoVerifiedCounts} out of ${item.spoCount} verified` : ''}
//          {arrayName === 'onlineCourse' ? `${item.onVerifiedCounts} out of ${item.onCount} verified` : ''}
//          {arrayName === 'responsibilities' ? `${item.resVerifiedCounts} out of ${item.resCount} verified` : ''}
//          {arrayName === 'booksAndChapter' ? `${item.bookVerifiedCounts} out of ${item.bookCount} verified` : ''}
//          {arrayName === 'consultancy' ? `${item.consVerifiedCounts} out of ${item.consCount} verified` : ''}
//         </>
//        ))}
//        </span>
//      </div> :""}
//     </div>
    
//      {items.length > 0 ? ( 
//        items.map((item: any, index: any) => (
//          <div key={index} className='valuesDiv'>
//            <div>

//              {arrayName === 'publications' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>
//                    <div className=''>
//                       <img className="mx-2" src={fileLogo}/>
//                      {/* <span className='maintextProf'>{item.academicYear} | </span> */}
//                      <span className='maintextProf'>{item.title} | </span>
//                      <span className='subTextProf'>{item.listedIn} </span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'patents' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>
//                    <div>
//                    <img className="mx-2" src={fileLogo}/>
//                      <span className='maintextProf'>{item.patentTitle} | </span>
//                      <span className='subTextProf'>{item.patentStatus}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'honoursAndAwards' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>
//                    <div>
//                    <img className="mx-2" src={awardLogo}/>
//                      <span className='maintextProf'>{item.awardTitle} | </span>
//                      <span className='subTextProf'>{item.awardPresentedBy}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'trainings' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)} >
//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.programTitle} | </span>
//                      <span className='subTextProf'>{item.duration}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'guidance' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>
//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.universityName} | </span>
//                      <span className='subTextProf'>{item.noOfScholars}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'sponsoredResearch' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>

//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.amountSanction} | </span>
//                      <span className='subTextProf'>{item.completionPercentage}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'onlineCourse' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>

//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.courseTitle} | </span>
//                      <span className='subTextProf'>{item.courseDuration}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'responsibilities' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>

//                    <div>
//                    <img className="mx-2" src={resp}/>
//                      <span className='maintextProf'>{item.responsibilityName} | </span>
//                      <span className='subTextProf'>{item.role}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'booksAndChapter' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>

//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.titleOfBook} | </span>
//                      <span className='subTextProf'>{item.type}</span>
//                    </div>

//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'consultancy' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>
//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.category} | </span>
//                      <span className='subTextProf'>{item.amountSanction}</span>
//                      <span className='subTextProf'>{item.completionPercentage}</span>
//                    </div>

//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//            </div>  
//          </div>
//        ))
//      ) : " "}
//    </div>
// </>
//     )
//   }

//   return (
//     <>
//     {view ? (
//       <CourseTable/>
//     ) : (
// <div className="row">
//       <div className="col-12">
//         <div className="mainheaderViewProficiency">Proficiency</div>
//         <div className='d-flex justify-content-between'>
//           <div className="greyfont">View proficiency details of the employee {employeeData.employeeId}</div>
//           <div>
//             <span className="bacdept cursorPointer labelinput">
//               <Link to="/faculty/secondsidebar/collections/employees" className='link'>
//                 <img src={back} className="img-fluid me-2" /> Back to Employee Home
//               </Link>
//             </span>
//           </div>
//         </div>
//         <div className='d-flex mt-2 align-items-end'>
//        <img src={profileImage} alt="Preview" className="me-3 img-fluid imgWidth" />
//           <div className='row '>
//             <div className='extraDatas my-1'>{employeeData.empName}<span className='emptype labelinput ms-2'><span className='ms-2'></span>{employeeData.empType}</span></div>
//             <div className='labelinput my-1'> {employeeData.employeeId} | {employeeData.designation} | {employeeData.deptId}</div>
//           </div>
//         </div>
//       </div>
//       <hr className='mt-3 ' />
//       <div className='row d-flex align-items-center acaYearPicker'>
//         <div className='labelinput textdropdown col-auto mt-3 '>
//           Showing proficiency details for the academic year
//         </div>
//         <div className="col-auto">
//           <select
//             className="form-select mt-2 labelinput"
//             name="classOfComplete"
//             style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
//             onChange={(e)=>setAcademicYr(e.target.value)}
//           >
//             <option selected>--Select--</option>
//             {academicYearFromDb.map((item:any) => (
//     <option key={item.id} value={item.academicYear} style={{ color: "black", fontSize: "14px" }}>
//       {item.academicYear}
//       </option>
//   ))}
//           </select>
//         </div>
//       </div>
//       <>
//         {Object.entries(data).map(([key, value]) => (
//           Array.isArray(value) ? renderArrayItems(key, value) : null
//         ))}
//       </>
//     </div>
//     )}
    
//     </>
//   );
// };










// import React, { useState, useEffect } from 'react';
// import { Link, useParams, useLocation } from 'react-router-dom';
// import user from "../../../../assets/user.png";
// import back from "../../../../assets/back.png";
// import { ProficiencyView ,AcademicYear ,PubliCationTableData} from '../../../../ApiServices';
// import "../../Employees/ViewProficiancy/ViewProficiancy.css";
// import YetToSubmit from "../../../../assets/yet to submit.png"
// import verfied from "../../../../assets/verified.png"
// import rejected from "../../../../assets/Icon ionic-ios-close-circle.png"
// import pending from "../../../../assets/Icon ionic-ios-alert.png"
// import { TbAwardFilled } from "react-icons/tb";
// import fileLogo from "../../../../assets/file/file.png"
// import awardLogo from "../../../../assets/award/award.png"
// import book from "../../../../assets/book/book.png"
// import resp from "../../../../assets/resp/resp.png"
// import CourseTable from '../../../../components/CourseDetailTable/CourseTable';


// export const ViewProficiancy = () => {
//   const [noImage, setNoImage] = useState(true);
//   const [profileImage, setProfileImage] = useState("")
//   const [data, setData] = useState({});
//   const [Verified,setVerified] = useState([])
//   const [academicYr ,setAcademicYr]=useState("")
//   const [academicYears,setAcademicYear] = useState([]);
//   const [selectedItem , setSelectedItem] = useState()
//   const [view , setView] = useState(false)
//   const [passingData , SetPassingData ] = useState()
//   const imageUrl = "";
//   const { id } = useParams();
//   console.log(id, "am the idddddddddd");
//   const location = useLocation();
//   // const employeeData = location.state?.employeeData ;
//   // console.log( location.state?.image, "locationImgae")
//   // const image = location.state?.image
//   const { employeeData, imagePath } = location.state || {};

//   useEffect(()=>{
//   console.log(passingData , "the data am passing ")
//   },[passingData])
 
//   const fetchData = async () => {
//     const imageUrls = "https://dev.vinbytes.com/kings-faculty/employee/get/uploaded/file?fileName=" + imagePath;
//     try {
//       const response = await fetch(imageUrls)
//       if (response.ok) {
//         setProfileImage(imageUrls)
//       }
//     } catch (error) {
//       console.error("Error making HTTP call:", error);
//     }
//   }

//   const acad = ()=>{
//     AcademicYear()
//     .then((res:any) =>{
//       console.log(res.data.data);
//       const uniqueAcademicYears = res.data.data.filter((item:any, index:any, self:any) =>
//         index === self.findIndex((t:any) => (
//           t.academicYear === item.academicYear
//         ))
//       );
//       setAcademicYear(uniqueAcademicYears)
//     }) 
//   }
//   const academicYearFromDb = academicYears;
//   useEffect(()=>{
//     acad()
//     },[])

  
//   useEffect(() => {
//     const data ={ 
//       id:id,
//       academicYear:academicYr
//     }
//     console.log(data , "dataa")
//     ProficiencyView(data)
//       .then((res) => {
//         console.log(res.data.data[0], "viewProficiency");
//         setSelectedItem(res.data.data[0])
//         setVerified(res.data.data)
//         setData(res.data.data[0]);
//       }).catch((err) => {
//         console.log(err);
//       });
//       fetchData()
//   }, [id , academicYr]);

//   const formatCamelCase = (str: string) => {
//     return str
//       .replace(/([a-z])([A-Z])/g, '$1 $2') 
//       .replace(/^./, str[0].toUpperCase()); 
//   };

//   const viewSpecificProficiency =(item:any)=>{
  
//     navigateView(item)
//    console.log(item , " passingData")
//   }

//   function navigateView(item: any){
//     PubliCationTableData(item).then((res:any)=>{
//       SetPassingData(res.data.data);
//       console.log(res.data.data, "dataaa")
//       // setViewTitle(res.data.data[0].proficiency.title)
//       // setPubIdView(res.data.data[0].proficiency.pubId)
//       // setPubImageUrl(res.data.data[0].proficiency.publicationPhoto)
//       // setViewSingle(true) 
//       setView(true)
      
//       const formattedCreatedDate = new Date(res.data.data[0].createdOn);
//       // const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");

//       let updatedOn = "";
//       if (res.data.data[0].updatedOn !== null) {
//           const formattedUpdatedDate = new Date(res.data.data[0].updatedOn);
//           // updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
//       }        
//       // setCreatedOn(createdOn);  
//       // setUpdatedOn(updatedOn);
//     }).catch((err:any)=>console.log(err))
//   }


//   const renderArrayItems = (arrayName: any, items: any) => {
//     console.log(items, "logg")
//     return (
//       <>
// {
//   view ? (
//      <CourseTable  data={passingData}/>
//   ) : (
//     <div key={arrayName}>   
//     <div className='d-flex align-items-center justify-content-between divHead'>
//     <div className='mainheader mb-2'>
//        {/* {items.length > 0 ? arrayName.charAt(0).toUpperCase() + arrayName.slice(1) : ""} */}
//        {items.length > 0 ? formatCamelCase(arrayName) : ""}
//      </div>
//     {items.length > 0 ? 
//      <div>
//      <span className='noOfVerified'>
//        {/* 1 of 2 verified */}
//        {Verified.map((item:any)=>(
//         <>
//          {/* {arrayName === 'publications' && item.pubVerifiedCounts && item.pubCount  ? `${item.pubVerifiedCounts} out of ${item.pubCount} verified` : ''}  */}
//          {arrayName === 'publications' ? `${item.pubVerifiedCounts} out of ${item.pubCount} verified` : ''}
//          {arrayName === 'patents' ? `${item.patVerifiedCounts} out of ${item.patCount} verified` : ''}
//          {arrayName === 'honoursAndAwards' ? `${item.honVerifiedCounts} out of ${item.honCount} verified` : ''}
//          {arrayName === 'trainings' ? `${item.trainVerifiedCounts} out of ${item.trainCount} verified` : ''}
//          {arrayName === 'guidance' ? `${item.guidVerifiedCounts} out of ${item.guidCount} verified` : ''}
//          {arrayName === 'sponsoredResearch' ? `${item.spoVerifiedCounts} out of ${item.spoCount} verified` : ''}
//          {arrayName === 'onlineCourse' ? `${item.onVerifiedCounts} out of ${item.onCount} verified` : ''}
//          {arrayName === 'responsibilities' ? `${item.resVerifiedCounts} out of ${item.resCount} verified` : ''}
//          {arrayName === 'booksAndChapter' ? `${item.bookVerifiedCounts} out of ${item.bookCount} verified` : ''}
//          {arrayName === 'consultancy' ? `${item.consVerifiedCounts} out of ${item.consCount} verified` : ''}
//         </>
//        ))}
//        </span>
//      </div> :""}
//     </div>
    
//      {items.length > 0 ? ( 
//        items.map((item: any, index: any) => (
//          <div key={index} className='valuesDiv'>
//            <div>

//              {arrayName === 'publications' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>
//                    <div className=''>
//                       <img className="mx-2" src={fileLogo}/>
//                      {/* <span className='maintextProf'>{item.academicYear} | </span> */}
//                      <span className='maintextProf'>{item.title} | </span>
//                      <span className='subTextProf'>{item.listedIn} </span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'patents' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>
//                    <div>
//                    <img className="mx-2" src={fileLogo}/>
//                      <span className='maintextProf'>{item.patentTitle} | </span>
//                      <span className='subTextProf'>{item.patentStatus}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'honoursAndAwards' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>
//                    <div>
//                    <img className="mx-2" src={awardLogo}/>
//                      <span className='maintextProf'>{item.awardTitle} | </span>
//                      <span className='subTextProf'>{item.awardPresentedBy}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'trainings' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)} >
//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.programTitle} | </span>
//                      <span className='subTextProf'>{item.duration}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'guidance' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>
//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.universityName} | </span>
//                      <span className='subTextProf'>{item.noOfScholars}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'sponsoredResearch' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>

//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.amountSanction} | </span>
//                      <span className='subTextProf'>{item.completionPercentage}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'onlineCourse' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>

//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.courseTitle} | </span>
//                      <span className='subTextProf'>{item.courseDuration}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'responsibilities' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>

//                    <div>
//                    <img className="mx-2" src={resp}/>
//                      <span className='maintextProf'>{item.responsibilityName} | </span>
//                      <span className='subTextProf'>{item.role}</span>
//                    </div>
//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'booksAndChapter' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>

//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.titleOfBook} | </span>
//                      <span className='subTextProf'>{item.type}</span>
//                    </div>

//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//              {arrayName === 'consultancy' && (
//                <>
//                  <div className='d-flex justify-content-between' onClick={()=>viewSpecificProficiency(item.pubId)}>
//                    <div>
//                    <img className="mx-2" src={book}/>
//                      <span className='maintextProf'>{item.category} | </span>
//                      <span className='subTextProf'>{item.amountSanction}</span>
//                      <span className='subTextProf'>{item.completionPercentage}</span>
//                    </div>

//                    <div className='mx-4'>
//                      <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
//                        {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
//                        {item.status === "Pending" && <img src={pending} className="statusIcon" />}
//                        {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
//                        {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
//                        <span className=''>{item.status}</span>
//                      </span>
//                    </div>
//                  </div>
//                </>
//              )}
//            </div>  
//          </div>
//        ))
//      ) : " "}
//    </div>
//   )
// } 
// </>
//     )
//   }

//   return (

//     <div className="row">
//       {
//         view ? "" : (
//           <>
//           <div className="col-12">
//           <div className="mainheaderViewProficiency">Proficiency</div>
//           <div className='d-flex justify-content-between'>
//             <div className="greyfont">View proficiency details of the employee {employeeData.employeeId}</div>
//             <div>
//               <span className="bacdept cursorPointer labelinput">
//                 <Link to="/faculty/secondsidebar/collections/employees" className='link'>
//                   <img src={back} className="img-fluid me-2" /> Back to Employee Home
//                 </Link>
//               </span>
//             </div>
//           </div>
//           <div className='d-flex mt-2 align-items-end'>
//          <img src={profileImage} alt="Preview" className="me-3 img-fluid imgWidth" />
//             <div className='row '>
//               <div className='extraDatas my-1'>{employeeData.empName}<span className='emptype labelinput ms-2'><span className='ms-2'></span>{employeeData.empType}</span></div>
//               <div className='labelinput my-1'> {employeeData.employeeId} | {employeeData.designation} | {employeeData.deptId}</div>
//             </div>
//           </div>
//         </div>
//         <hr className='mt-3 ' />
//         <div className='row d-flex align-items-center acaYearPicker'>
//           <div className='labelinput textdropdown col-auto mt-3 '>
//             Showing proficiency details for the academic year
//           </div>
//           <div className="col-auto">
//             <select
//               className="form-select mt-2 labelinput"
//               name="classOfComplete"
//               style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
//               onChange={(e)=>setAcademicYr(e.target.value)}
//             >
//               <option selected>--Select--</option>
//               {academicYearFromDb.map((item:any) => (
//       <option key={item.id} value={item.academicYear} style={{ color: "black", fontSize: "14px" }}>
//         {item.academicYear}
//         </option>
//     ))}
//             </select>
//           </div>
//         </div>
//         </>
//         )
//       }
//       <>
//         {Object.entries(data).map(([key, value]) => (
//           Array.isArray(value) ? renderArrayItems(key, value) : null
//         ))}
//       </>
//     </div>
//   );
// };





import React, { useState, useEffect } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import user from "../../../../assets/user.png";
import back from "../../../../assets/back.png";
import { ProficiencyView ,AcademicYear , ProficiencyViewCall, host,fileDownload} from '../../../../ApiServices';
import "../../Employees/ViewProficiancy/ViewProficiancy.css";
import YetToSubmit from "../../../../assets/yet to submit.png"
import verfied from "../../../../assets/verified.png"
import rejected from "../../../../assets/Icon ionic-ios-close-circle.png"
import pending from "../../../../assets/Icon ionic-ios-alert.png"
import { TbAwardFilled } from "react-icons/tb";
import fileLogo from "../../../../assets/file/file.png"
import awardLogo from "../../../../assets/award/award.png"
import book from "../../../../assets/book/book.png"
import resp from "../../../../assets/resp/resp.png"
import CourseTable from '../../../../components/CourseDetailTable/CourseTable';
import { format as formatDate } from "date-fns";
import { ToastContainer , toast  } from "react-toastify";
// import user from "../../../../../assets/user.png";
import users from "../../../../assets/user.png";
import yearList from "../../../../YearList.json"; 

import noRecord from "../../../../assets/norecords.png"
import { format, parseISO } from 'date-fns';


export const ViewProficiancy = () => {
  const [noImage, setNoImage] = useState(false);
  const [profileImage, setProfileImage] = useState("")
  const [data, setData] = useState({});
  const [Verified,setVerified] = useState([])
  const [academicYr ,setAcademicYr]=useState("")
  const [academicYears,setAcademicYear] = useState([]);
  const [createdOn , setCreatedOn] = useState<string>('');
  const [updatedOn , setUpdatedOn] = useState<string>('');
  const [pubIdView , setPubIdView] = useState<string>('');
  const [selectedItem , setSelectedItem] = useState()
  const [viewTitle,setViewTitle] = useState();
  // const [selectedItem, setselectedItem] = useState<any>([])
  const heading = viewTitle
  const [view , setView] = useState(false)
  const [passingData , SetPassingData ] = useState([])
  const [headers , setHeaders] = useState()
  const [subheading , setsubheading] = useState<string>()
  const [viewType ,setViewType] = useState('')
  const [fileView , setFileView] = useState("")
  const imageUrl = "";
  const { id } = useParams();
  console.log(id, "am the idddddddddd");
  const location = useLocation();
  const employeeData = location.state?.employeeData.data ;
  console.log( location.state?.employeeData.data[0].image, "locationImgae")
  const image = location.state?.employeeData.data[0].image
  console.log( location.state?.image, "locationImgaepk")
  const [period,setperiod]  = useState<string>('');
  // const { employeeData, imagePath } = location.state || {};

  console.log(period , "hello")

  console.log(viewType , "typeeee")
  
  const PublicationHeader=[
    {
      "title": "Academic Year",
      "key":"academicYear"
    },
    {
      "title": "Listed in",
      "key":"listedIn"
    },
    {
      "title": "Title of the Publication",
      "key":"title"
    }, 
    {
      "title": "Name of the Journal",
      "key":"journalName"
    },
    {
      "title": "DOI",
      "key":"doi"
    },  
    // {
    //   "title": "h-index",
    //   "key":"hindex"
    // }, {
    //   "title": "i10-index",
    //   "key":"i10Index"
    // }, 
    {
      "title": "Author Position",
      "key":"authorPosition"
    }, {
      "title": "Publication URL",
      "key":"publicationUrl"
    }, {
      "title": "Proof Document",
      "key":"uploadFile"
    }
  ]

  const PatentViewHeaders=[
    {
      "title": "Academic Year",
      "key":"academicYear"
    },
    {
      "title": "File Number",
      "key":"fileNumber"
    }, {
      "title": "Title of the patent",
      "key":"patentTitle"
    }, {
      "title": "Agency Name",
      "key":"agencyName"
    }, {
      "title": "Status",
      "key":"patentStatus"
    }, {
      "title": "Year",
      "key":"year"
    },{
        "title": "Proof Document",
        "key":"uploadFile"
    }
  ]


const TrainingViewHeaders=[
{
  "title": "Academic Year",
  "key":"academicYear"
},
{ title: "Type",
   key: "values"
},{
  "title": "Title of the programme",
  "key":"programTitle"
}, {
  "title": "Organization (AICTE/AU..)",
  "key":"organization"
}, {
  "title": "Acted as",
  "key":"actedAs"
}, {
  "title": "Duration",
  "key":"duration"
}, {
  "title": "Period",
  "key":"period"
}, {
  "title": "Proof Document",
  "key":"uploadFile"
}
]

const GuidanceViewHeaders=[
{
  title: "Academic Year",
  key:"academicYear",
},
{
  title: "Name of the University",
  key:"universityName"
},
{
  title: "No. of Past Scholars",
  key:"noOfScholarsPast"
}, 
,
{
  title: "No. of Present Scholars",
  key:"noOfScholars"
}, 
{
  title: "Proof Document",
  key:"uploadFile"
}, 
]

const SponsoredViewHeaders=[
{
  "title": "Academic Year",
  "key":"academicYear"
},
// , {
//   "title": "Title",
//   "key":"tittle"
// },
{
  "title": "Category",
  "key":"category"
}, {
  "title": "Agency",
  "key":"agency"
}, {
  "title": "Sanctioned Amount in Rupees",
  "key":"amountSanction"
},  {
  "title": "Completion Percentage",
  "key":"completionPercentage"
}, {
  "title": "Sanctioned Letter",
  "key":"fileUpload"
},
]

const OnlineCourseViewHeaders=[
{
  "title": "Academic Year",
  "key":"academicYear"
},
{
  "title": "Course Title",
  "key":"courseTitle"
},
{
  "title": "Awarding Institution",
  "key":"awardingInstitution"
},
{
  "title": "Grade or % of marks",
  "key":"grade"
},
{
  "title": "Course Completed on",
  "key":"completionOn"
},
{
  "title": "Course duration",
  "key":"courseDuration"
}, 
 
{
  "title": "Proof Document", 
  "key":"uploadFile"
}
]

const ResponsibilitiesViewHeaders=[
{
  "title": "Academic Year",
  "key":"academicYear"
},
{
  "title": "Name of the Responsibility",
  "key":"responsibilityName"
}, {
  "title": "Role",
  "key":"role"
}, {
  "title": "Duration",
  "key":"duration"
}, {
  "title": "In-charge / HOD",
  "key":"inCharge"
}, {
  "title": "Certificate, if any",
  "key":"uploadFile"
}
]

const BooksChapterEditsViewHeaders=[
{
  "title": "Academic Year",
  "key":"academicYear"
},
{
  "title": "Type",
  "key":"type"
},
{
  "title": "Title of the book/chapter",
  "key":"titleOfBook"
},
{
  "title": "Authors",
  "key":"authors"
},
{
  "title": "Publisher",
  "key":"publisher"
},
{
  "title": "Year of Publication",
  "key":"yearOfPublications"
},
{
  "title": "Author Position",
  "key":"authorPosition"
},
{
  "title": "ISBN Number",
  "key":"isbnNo"
},
{
  "title": "Proof Document",
  "key":"uploadFile"
}
]



 // const backtoview =" "

const ConsultancyViewHeaders = [
{
  "title": "Academic Year",
    "key":"academicYear"
 },
 {
  "title": "Category",
    "key":"category"
 },
 {
  "title": "Agency",
    "key":"agency"
 },
 {
  "title": "Sanctioned Amount in Rupees",
    "key":"amountSanction"
 },
 {
  "title": "Completion Percentage",
    "key":"completionPercentage"
 },
 {
  "title": "Proof Document",
    "key":"fileUpload"
 },
]



  useEffect(()=>{
  console.log(passingData , "the data am passing ")
  },[passingData])
 

  const fetchData = async () => {
    const imageUrls = host+"employee/get/uploaded/file?fileName=" + image;
    try {
      const response = await fetch(imageUrls)
      if (response.ok) {
        setProfileImage(imageUrls)
        setNoImage(true);
      }
    } catch (error) {
      console.error("Error making HTTP call:", error);
      setNoImage(false);
    }
  }

  const acad = ()=>{
    AcademicYear()
    .then((res:any) =>{
      console.log(res.data.data);
      const uniqueAcademicYears = res.data.data.filter((item:any, index:any, self:any) =>
        index === self.findIndex((t:any) => (
          t.academicYear === item.academicYear
        ))
      );
      setAcademicYear(uniqueAcademicYears)
    }) 
  }
  const academicYearFromDb = academicYears;
  useEffect(()=>{
    acad()
    },[])

  
  useEffect(() => {
    const data ={ 
      id:id,
      academicYear:academicYr
    }
    console.log(data , "dataa")
    ProficiencyView(data)
      .then((res) => {
        console.log(res.data.data[0], "viewProficiency");
        setSelectedItem(res.data.data[0])
        setVerified(res.data.data)
        setData(res.data.data[0]);
      }).catch((err) => {
        console.log(err);
      });
      fetchData()
  }, [id , academicYr]);

  const formatCamelCase = (str: string) => {
    return str
      .replace(/([a-z])([A-Z])/g, '$1 $2') 
      .replace(/^./, str[0].toUpperCase()); 
  };

  const HonoursViewHeaders=[
    {
      "title": "Academic Year",
      "key":"academicYear"
    },
    {
      "title": "Type",
      "key":"type"
    }
    ]
  const viewSpecificProficiency =(item:any , heading?:any , type?:any)=>{
  console.log(type,heading,item,"yh8igykig8igo8igokig");
  console.log(item , "passingData")
    setViewType(type)
    navigateView(item)
   setHeaders(heading)
   setFileView(item)
  //  FileDownload(item)
  if (type === "Awards") {
    HonoursViewHeaders.push(
      {
        "title": "Award Title",
        "key": "awardTitle"
      },
      {
        "title": "Award Presented by",
        "key": "presentedBy"
      },
      {
        "title": "Proof Document",
        "key": "uploadFile"
      }
    );
    } else if(type === "Fellowships"){
    HonoursViewHeaders.push(
      {
        "title": "Fellowship Title",
        "key": "fellowTitle"
      },
      {
        "title": "Fellowship Awarded by",
        "key": "fellowPresentedBy"
      },
      {
        "title": "Proof Document",
        "key": "uploadFile"
      }
    );
    }else if(type === "Honours"){
    HonoursViewHeaders.push(
      {
        "title": "Honours Title",
        "key": "honourTitle"
      },
      {
        "title": "Honours Presented by",
        "key": "honourPresentedBy"
      },
      {
        "title": "Proof Document",
        "key": "uploadFile"
      }
    );
    }else if(type === "Memberships"){
    HonoursViewHeaders.push(
      {
        "title": "Organisation Name",
        "key": "organisationName"
      },
      {
        "title": "Role",
        "key": "organisationRole"
      },
      {
        "title": "Current Status",
        "key": "currentStatus"
      },
      // {
      //  "title":"Tenure",
      //   "key" :"period"
      // },
      {
        "title": "Proof Document",
        "key": "uploadFile"
      },
    );
    }
  }
  useEffect(()=>{
    console.log("togggled")
  },[viewSpecificProficiency])

  const FileDownload = () => {
    const itemId = fileView;
    const payload = itemId.startsWith("PUB")
      ? "publication?pubId="
      : itemId.startsWith("PAT")
      ? "patent?patId="
      : itemId.startsWith("HON")
      ? "honours/awards?honId="
      : itemId.startsWith("TRAIN")
      ? "trainings?trainId="
      : itemId.startsWith("GUID")
      ? "guidance?guidId="
      : itemId.startsWith("SPR")
      ? "sponsoredResearch?sprId="
      : itemId.startsWith("ON")
      ? "online/courses?coId="
      : itemId.startsWith("RES")
      ? "responsibilities?resId="
      : itemId.startsWith("BAC")
      ? "booksAndChapter?bacId="
      : itemId.startsWith("CONT")
      ? "consultancy?contId="
      : "";
  
    const url = host + 'proficiency/get/' + payload + itemId + '&keyword=file';
    window.open(url, '_blank');
    fileDownload(url)
      .then((res) => {
      })
      .catch((err) => {
      });
  };
  


  // const SelectedSection = passingData.proficiency
  // console.log(SelectedSection,"SelectedSection");
  
  // const viewT = SelectedSection === 'Publications' ? selectedItem.proficiency?.title :
  //               SelectedSection === 'Patents' ? selectedItem.proficiency?.patentTitle :
  //               SelectedSection === "Trainings" ? selectedItem.proficiency?.programTitle :
  //               SelectedSection === "Research Guidance" ? selectedItem.proficiency?.universityName :
  //               SelectedSection === "Responsibilities" ? selectedItem?.proficiency?.responsibilityName : 
  //               SelectedSection === "Books & Chapters" ? selectedItem?.proficiency?.category :
  //               SelectedSection === "Honours & Awards" ? selectedItem?.proficiency?.type : selectedItem?.proficiency?.category;
  // const viewType = SelectedSection === 'Honours & Awards' ? selectedItem?.proficiency?.type : ''


  function navigateView(item: any){
    const url = item.startsWith("PUB")? "publication?pubId" : item.startsWith("PAT")? "patent?patId" : item.startsWith("HON")? "honours/awards?honId" : item.startsWith("TRAIN")? "trainings?trainId": item.startsWith("GUID")? "guidance?guidId": item.startsWith("SPR")? "sponsoredResearch?sprId": item.startsWith("ON")? "online/courses?coId": item.startsWith("RES")? "responsibilities?resId": item.startsWith("BAC")? "booksAndChapter?bacId": item.startsWith("CONT")? "consultancy?contId" :""
    const data = {
    url:url ,
    item:item
    }
    ProficiencyViewCall(data).then((res:any)=>{
      SetPassingData(res.data.data[0]);
      console.log(res.data.data[0], "dataaa")
      // setViewTitle(res.data.data[0].proficiency.title)
  
      if (res.data.data[0].proficiency.title) {
        setViewTitle(res.data.data[0].proficiency.title);
        setsubheading("View details of the research published")
      } else if (res.data.data[0].proficiency.patentTitle) {
        setViewTitle(res.data.data[0].proficiency.patentTitle);
        setsubheading("View details of patent")
      }else if (res.data.data[0].proficiency.programTitle) {
        setViewTitle(res.data.data[0].proficiency.programTitle);
        setsubheading("View details of workshop")
      }else if (res.data.data[0].proficiency.universityName) {
        setViewTitle(res.data.data[0].proficiency.universityName);
        setsubheading("View details of Research Guidance")
      }else if (res.data.data[0].proficiency.responsibilityName) {
        setViewTitle(res.data.data[0].proficiency.responsibilityName);
        setsubheading("View details of additional roles and responsibilities")
      }else if (res.data.data[0].proficiency.category) {
        setViewTitle(res.data.data[0].proficiency.category);
        setsubheading("View details of the ongoing sponsored research project")
      }else if (res.data.data[0].proficiency.yearOfPublications) {
        console.log(res.data.data[0] , "honType")
        setViewTitle(res.data.data[0].proficiency.type);
        setsubheading("View details of the book published")
      }else if (res.data.data[0].proficiency.awardReceived) {
        console.log(res.data.data[0] , "honType")
        setViewTitle(res.data.data[0].proficiency.type);   
        setsubheading("View details of the Honours & Awards received")
      }else if (res.data.data[0].proficiency.courseTitle) {
        setViewTitle(res.data.data[0].proficiency.courseTitle);
        setsubheading("View details of online certification course")
      } else {
        setViewTitle(res.data.data[0].proficiency.type);
        setsubheading("View details of the research published7")
      }
      setPubIdView(res.data.data[0].proficiency.pubId)
      // setPubImageUrl(res.data.data[0].proficiency.publicationPhoto)
      // setViewSingle(true) 
      setView(true)
      
      const formattedCreatedDate = new Date(res.data.data[0].createdOn);
      const createdOn = formatDate(formattedCreatedDate, "dd MMM yyyy hh:mm a");

      let updatedOn = "";
      if (res.data.data[0].updatedOn !== null) {
          const formattedUpdatedDate = new Date(res.data.data[0].updatedOn);
          updatedOn = formatDate(formattedUpdatedDate, "dd MMM yyyy hh:mm a");
      }    
      console.log(res.data.data[0],"hello2");
      setCreatedOn(createdOn);  
      setUpdatedOn(updatedOn);
    }).catch((err:any)=>console.log(err))

  }

  // console.log('Received state:', { employeeData, imagePath });
  const renderArrayItems = (arrayName: any, items: any) => {
    console.log(items, "logg")
    return (
      <>
    <div key={arrayName}>   
    <div className='d-flex align-items-center justify-content-between divHead'>
    <div className='mainheader my-2'>
       {/* {items.length > 0 ? arrayName.charAt(0).toUpperCase() + arrayName.slice(1) : ""} */}
       {arrayName === "responsibilities" && items.length > 0 && "Additional "}
       {items.length > 0 ? formatCamelCase(arrayName) : ""}
     </div>
    {items.length > 0 ? 
     <div>
     <span className='noOfVerified'>
       {/* 1 of 2 verified */}
       {Verified.map((item:any)=>(
        <>
         {/* {arrayName === 'publications' && item.pubVerifiedCounts && item.pubCount  ? `${item.pubVerifiedCounts} out of ${item.pubCount} verified` : ''}  */}
         {arrayName === 'publications' ? `${item.pubVerifiedCounts} out of ${item.pubCount} verified` : ''}
         {arrayName === 'patents' ? `${item.patVerifiedCounts} out of ${item.patCount} verified` : ''}
         {arrayName === 'honoursAndAwards' ? `${item.honVerifiedCounts} out of ${item.honCount} verified` : ''}
         {arrayName === 'trainings' ? `${item.trainVerifiedCounts} out of ${item.trainCount} verified` : ''}
         {arrayName === 'guidance' ? `${item.guidVerifiedCounts} out of ${item.guidCount} verified` : ''}
         {arrayName === 'sponsoredResearch' ? `${item.spoVerifiedCounts} out of ${item.spoCount} verified` : ''}
         {arrayName === 'onlineCourse' ? `${item.onVerifiedCounts} out of ${item.onCount} verified` : ''}
         {arrayName === 'responsibilities' ? `${item.resVerifiedCounts} out of ${item.resCount} verified` : ''}
         {arrayName === 'booksAndChapter' ? `${item.bookVerifiedCounts} out of ${item.bookCount} verified` : ''}
         {arrayName === 'consultancy' ? `${item.consVerifiedCounts} out of ${item.consCount} verified` : ''}
        </>
       ))}
       </span>
     </div> :""}
    </div>
    
     {items.length > 0 ? ( 
       items.map((item: any, index: any) =>
       {
        console.log(item, "check")
        return(
          <div key={index} className='valuesDiv cursorPointer mb-2'>
           <div>

             {arrayName === 'publications' && (
               <>
                 <div className='d-flex justify-content-between paddingDiv' onClick={()=>viewSpecificProficiency(item.pubId , PublicationHeader)}>
                   <div className=''>
                      <img className="mx-2" src={fileLogo}/>
                     {/* <span className='maintextProf'>{item.academicYear} | </span> */}
                     <span className='maintextProf'>{item.title} | </span>
                     <span className='subTextProf'>{item.listedIn} | </span>
                     <span className='subTextProf'>{item.academicYear}</span>
                   </div>
                   <div className='statusboxwidth me-2 me-md-0'>
                     <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
                       {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
                       {item.status === "Pending" && <img src={pending} className="statusIcon" />}
                       {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
                       {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
                       <span className=''>{item.status}</span>
                     </span>
                   </div>
                 </div>
               </>
             )}
             {arrayName === 'patents' && (
               <>
                 <div className='d-flex justify-content-between paddingDiv' onClick={()=>viewSpecificProficiency(item.patId , PatentViewHeaders)}>
                   <div>
                   <img className="mx-2" src={fileLogo}/>
                     <span className='maintextProf'>{item.patentTitle} | </span>
                     <span className='subTextProf'>{item.patentStatus} | </span>
                     <span className='subTextProf'>{item.academicYear}</span>
                   </div>
                   <div className='statusboxwidth me-2 me-md-0'>
                     <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
                       {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
                       {item.status === "Pending" && <img src={pending} className="statusIcon" />}
                       {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
                       {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
                       <span className=''>{item.status}</span>
                     </span>
                   </div>
                 </div>
               </>
             )}
             {arrayName === 'honoursAndAwards' && (
               <>
                 <div className='d-flex justify-content-between paddingDiv' onClick={()=>viewSpecificProficiency(item.honId ,HonoursViewHeaders,item.type)}>
                   <div>
                   <img className="mx-2" src={awardLogo}/>
                   {
                    item.type === "Awards" ? (
                      <>
                      <span className='maintextProf'>{item.awardTitle} | </span>
                      <span className='subTextProf'>{item.presentedBy} | </span>
                      <span className='subTextProf'>{item.academicYear}</span>
                      </>
                    ) : item.type=== "Honours" ?(
                      <>
                      <span className='maintextProf'>{item.honourTitle} | </span>
                      <span className='subTextProf'>{item.honourPresentedBy} | </span>
                      <span className='subTextProf'>{item.academicYear}</span>
                      </>
                    ): item.type=== "Memberships" ?(
                      <>
                      <span className='maintextProf'>{item.organisationRole} | </span>
                      <span className='subTextProf'>{item.organisationName} | </span>
                      <span className='subTextProf'>{item.academicYear}</span>
                      </>
                    ) : item.type=== "Fellowships" ?(
                      <>
                      <span className='maintextProf'>{item.fellowTitle} | </span>
                      <span className='subTextProf'>{item.fellowPresentedBy} | </span>
                      <span className='subTextProf'>{item.academicYear}</span>
                      </>
                    ) :""

                   }
                     
                   </div>
                   <div className='statusboxwidth me-2 me-md-0'>
                     <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
                       {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
                       {item.status === "Pending" && <img src={pending} className="statusIcon" />}
                       {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
                       {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
                       <span className=''>{item.status}</span>
                     </span>
                   </div>
                 </div>
               </>
             )}
             {arrayName === 'trainings' && (  
               <>
                     {/* SelectedSection === "Trainings" ? TrainingViewHeaders : */}
                 <div className='d-flex justify-content-between paddingDiv' onClick={()=>viewSpecificProficiency(item.trainId,TrainingViewHeaders)} >
                   <div>
                   <img className="mx-2" src={book}/>
                     <span className='maintextProf'>{item.programTitle} | </span>
                     <span className='subTextProf'>{item.duration} | </span>
                     <span className='subTextProf'>{item.academicYear}</span>
                   </div>
                   <div className='statusboxwidth me-2 me-md-0'>
                     <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
                       {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
                       {item.status === "Pending" && <img src={pending} className="statusIcon" />}
                       {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
                       {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
                       <span className=''>{item.status}</span>
                     </span>
                   </div>
                 </div>
               </>
             )}
             {arrayName === 'guidance' && (
               <>
                 <div className='d-flex justify-content-between paddingDiv' onClick={()=>viewSpecificProficiency(item.guidId , GuidanceViewHeaders )}>
                   <div>
                   <img className="mx-2" src={book}/>
                     <span className='maintextProf'>{item.universityName} | </span>
                     <span className='subTextProf'>{item.noOfScholars} Scholars | </span>
                     <span className='subTextProf'>{item. academicYear} </span>
                   </div>
                   <div className='statusboxwidth me-2 me-md-0'>
                     <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
                       {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
                       {item.status === "Pending" && <img src={pending} className="statusIcon" />}
                       {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
                       {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
                       <span className=''>{item.status}</span>
                     </span>
                   </div>
                 </div>
               </>
             )}
             {arrayName === 'sponsoredResearch' && (
               <>
                 <div className='d-flex justify-content-between paddingDiv' onClick={()=>viewSpecificProficiency(item.sprId , SponsoredViewHeaders)}>

                   <div>
                   <img className="mx-2" src={book}/>
                   <span className='maintextProf'>{item.category} | </span>
                     <span className='subTextProf'> INR {item.amountSanction} | </span>
                     <span className='subTextProf'>{item.completionPercentage}% completed | </span>
                     <span className='subTextProf'>{item. academicYear} </span>
                   </div>
                   <div className='statusboxwidth me-2 me-md-0'>
                     <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
                       {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
                       {item.status === "Pending" && <img src={pending} className="statusIcon" />}
                       {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
                       {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
                       <span className=''>{item.status}</span>
                     </span>
                   </div>
                 </div>
               </>
             )}
             {arrayName === 'onlineCourse' && (
               <>
                 <div className='d-flex justify-content-between paddingDiv' onClick={()=>viewSpecificProficiency(item.coId , OnlineCourseViewHeaders)}>

                   <div>
                   <img className="mx-2" src={book}/>
                     <span className='maintextProf'>{item.courseTitle} | </span>
                     <span className='subTextProf'>{item.courseDuration} | </span>
                     <span className='subTextProf'>{item.academicYear}</span>
                   </div>
                   <div className='statusboxwidth me-2 me-md-0'>
                     <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
                       {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
                       {item.status === "Pending" && <img src={pending} className="statusIcon" />}
                       {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
                       {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
                       <span className=''>{item.status}</span>
                     </span>
                   </div>
                 </div>
               </>
             )}
             {arrayName === 'responsibilities' && (
               <>
                 <div className='d-flex justify-content-between paddingDiv' onClick={()=>viewSpecificProficiency(item.resId , ResponsibilitiesViewHeaders )}>

                   <div>
                   <img className="mx-2" src={resp}/>
                     <span className='maintextProf'>{item.responsibilityName} | </span>
                     <span className='subTextProf'>{item.role} | </span>
                     <span className='subTextProf'>{item.academicYear}</span>
                   </div>
                   <div className='statusboxwidth me-2 me-md-0'>
                     <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
                       {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
                       {item.status === "Pending" && <img src={pending} className="statusIcon" />}
                       {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
                       {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
                       <span className=''>{item.status}</span>
                     </span>
                   </div>
                 </div>
               </>
             )}
             {arrayName === 'booksAndChapter' && (
               <>
                     {/* SelectedSection === "Books & Chapters" ? BooksChapterEditsViewHeaders : */}
                 <div className='d-flex justify-content-between paddingDiv' onClick={()=>viewSpecificProficiency(item.bacId , BooksChapterEditsViewHeaders)}>

                   <div>
                   <img className="mx-2" src={book}/>
                     <span className='maintextProf'>{item.titleOfBook} | </span>
                     <span className='subTextProf'>{item.type} | </span>
                     <span className='subTextProf'>{item.academicYear}</span>
                   </div>

                   <div className='statusboxwidth me-2 me-md-0'>
                     <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
                       {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
                       {item.status === "Pending" && <img src={pending} className="statusIcon" />}
                       {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
                       {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
                       <span className=''>{item.status}</span>
                     </span>
                   </div>
                 </div>
               </>
             )}
             {arrayName === 'consultancy' && (
               <>
                 <div className='d-flex justify-content-between paddingDiv' onClick={()=>viewSpecificProficiency(item.contId , ConsultancyViewHeaders)}>
                   <div>
                   <img className="mx-2" src={book}/>
                     <span className='maintextProf'>{item.category} | </span>
                     <span className='subTextProf'> INR {item.amountSanction} | </span>
                     <span className='subTextProf'>{item.completionPercentage}% completed | </span>
                     <span className='subTextProf'>{item. academicYear} </span>
                   </div>

                   <div className='statusboxwidth me-2 me-md-0'>
                     <span className={item.status === "Yet to submit" ? "bg-YetToSubmit" : item.status === "Verified" ? "bg-Verified" : item.status === "Pending" ? "bg-Pending" : item.status === "Rejected" ? "bg-Rejected" : ""}>
                       {item.status === "Yet to submit" && <img src={YetToSubmit} className="statusIcon" />}
                       {item.status === "Pending" && <img src={pending} className="statusIcon" />}
                       {item.status === "Rejected" && <img src={rejected} className="statusIcon" />}
                       {item.status === "Verified" && <img src={verfied} className="statusIcon" />}
                       <span className=''>{item.status}</span>
                     </span>
                   </div>
                 </div>
               </>
             )}
           </div>  
         </div>
        )
       }
        )
     ) : " "}
   </div>
     </>
    )
  }

  return (

    <div className="row">
      {
        view ? "" : (
          <>
         
            {/* <span>{item?.employeeId}</span> */}
          
          {employeeData.map((item:any , index:any)=>(
          <div className="col-12">
          <div className="mainheaderViewProficiency">Proficiency</div>
          <div className='d-flex justify-content-between'>
            <div className="greyfont">View proficiency details of the employee '{item?.employeeId}'</div>
            <div>
              <span className="bacdept cursorPointer labelinput">
                <Link to={`/faculty/secondsidebar/collections/employees/employeebio/${item?.employeeId}`} className='link'>
                  <img src={back} className="img-fluid me-2" /> Back to Employee Home
                </Link>
              </span>
            </div>
          </div>
          <div className='d-flex mt-2 align-items-end'>
          {noImage ? (
  <img src={profileImage} alt="Preview" className="me-3 img-fluid imgWidth" />
) : (
  <img src={users} alt="" className="me-3 img-fluid imgW" />
)}

            <div className='row '>
              <div className='extraDatas my-1'>{item?.empName}<span className='emptype labelinput ms-2'><span className='ms-2'></span>{item?.empType}</span></div>
              <div className='labelinput my-1'> {item?.employeeId} | {item?.designation} | {item?.deptId}</div>
            </div>
          </div>
        </div>
        ))}
        <hr className='mt-3 ' />
        {/* <div className='row d-flex align-items-center acaYearPicker'>
          <div className='labelinput textdropdown col-auto mt-3 '>
            Showing proficiency details for the academic year
          </div>
          <div className="col-auto">
            <select
              className="form-select mt-2 labelinput"
              name="classOfComplete"
              style={{ fontSize: "14px", fontFamily: "Satoshi medium" }}
              onChange={(e)=>setAcademicYr(e.target.value)}
            >
              <option selected value={""}>All</option>
              {yearList.map((item:any) => (
      <option key={item} value={item} style={{ color: "black", fontSize: "14px" }}>
        {item}
        </option>
    ))}
            </select>
          </div>
        </div> */}
        </>
        )
      }
      {view ? <CourseTable data={[passingData]} headers={headers} back={()=>{setView(!view)}} heading = {heading} createdOn = {createdOn}
      updatedOn = {updatedOn} subheading={subheading} FileDownload={()=>FileDownload()} pubIdView={pubIdView} /> : (
<>
        {Object.entries(data).some(([_, value]) => Array.isArray(value) && value.length > 0) ? (
          Object.entries(data).map(([key, value]) => 
            Array.isArray(value) && value.length > 0 ? (
              renderArrayItems(key, value)  
            ) : null
          )
        ) : (
          <div className="d-flex justify-content-center">
            <div className='text-center'>
              <img src={noRecord} alt="No record" /> <br />
              <p className="mt-3 text-center labelinput pb-0" style={{ color: '#1B1922' }}>
                This faculty has not added <br />proficiency details .
              </p>
            </div>
          </div>
        )}
      </>

      )}
  
    </div>
  );
};


