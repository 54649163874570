import axios from "axios";
import { formatValue } from "react-currency-input-field";
import { date } from "yup";


 /// export const host = "https://dev.vinbytes.com/kings-faculty/";
export const host = "https://faculty.kingsedu.ac.in/be-faculty/"
// export const host = "http://localhost:8081/";

export const login=(token)=>{
  return axios
    .post(host+"auth/login?token=" + token)
    .then((res)=>{
    return res;
    })
  }

 const getHeaders = () => ({
  'Content-Type': 'application/json',
  'Authorization': `Bearer ${sessionStorage.getItem('accessToken')}`,
  'Access-Control-Allow-Headers': 'Content-Type',
  'Access-Control-Allow-Origin': '*',
});
//update password
export const updatePassword = (data) => {
  return axios.post(host + "auth/update/password", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
// Proficiency
//online courses
export const OnlineCourseCreate = (data) => {
  console.log(data);
  return axios.post(host + "proficiency/online/courses", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const OnlineCourseUpdate = (data) => {
  return axios.post(host + "proficiency/online/courses", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const OnlineCourseGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/online/courses?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const OnlineCourseGetTableData = (id) => {
  return axios
    .get(host + "proficiency/get/online/courses?coId=" + id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
export const DeleteTabledata = (url) => {
  return axios.post(host + "proficiency/delete" + url, {}, { headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const fileDownload = (url) => {
  return axios.get(url).then((res) => {
    return res;
  });
};
//puplication
// export const PubliCationCreate=(data)=>{
//   console.log(data)
// return axios
//   .post(host+"/proficiency/publication",data)
//   .then((res)=>{
//     return res;
//   })
// }
 
// export const PubliCationUpdate=(data)=>{
//   return axios
//   .post(host+"/proficiency/publication",data)
//   .then((res)=>{
//      return res;
//   })
//  }
 
// Responsibilities
 
export const ResponsibilitiesCreate = (data) => {
  return axios.post(host + "proficiency/responsibilities", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const ResponsibilitiesGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/responsibilities?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const ResponsibilitiesGetTableData = (id) => {
  return axios
    .get(host + "proficiency/get/responsibilities?resId=" + id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
// export const patentsUpdate=(data)=>{
//   return axios
//   .post(host+"/proficiency/patent",data)
//   .then((res)=>{
//      return res;
//   })
//  }
 
//Books and chapter
 
export const BooksAndChapterCreate = (data) => {
  return axios.post(host + "proficiency/booksAndChapter", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
// export const honourUpdate=(data)=>{
//   return axios
//   .post(host+"/proficiency/honours/awards",data)
//   .then((res)=>{
//      return res;
//   })
//  }
 
export const BooksAndChapterGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/booksAndChapter?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const BooksAndChapterGetTableData = (id) => {
  return axios
    .get(host + "proficiency/get/booksAndChapter?bacId=" + id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
//Consultancy
export const ConsultancyCreate = (data) => {
  return axios.post(host + "proficiency/consultancy", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const ConsultancyGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/consultancy?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const ConsultancyGetTableData = (id) => {
  return axios
    .get(host + "proficiency/get/consultancy?contId=" + id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
//Performance
export const PerformanceCreate = (data) => {
  return axios.post(host + "proficiency/performance", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const PerformanceGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/performance?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const PerformanceGetTableData = (id) => {
  return axios
    .get(host + "proficiency/get/performance?perfId=" + id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
//honourCreate
// export const honourCreate=(data)=>{
//   console.log(data)
// return axios
//   .post(host+"proficiency/honours/awards",data)
//   .then((res)=>{
//     return res;
//   })
// }
 
// export const honourUpdate=(data)=>{
//   return axios
//   .post(host+"/proficiency/honours/awards",data)
//   .then((res)=>{
//      return res;
//   })
//  }
 
// export const honourGetTable=(data)=>{
//  return axios
//  .get(host+"proficiency/get/honours/awards?"+"empId="+data+"&keyword=&page=")
//  .then((res)=>{
//     return res;
//  })
// }
 
// export const honourGetTableData=()=>{
//     return axios
//     .get(host+"/proficiency/get/honours/awards?coId=ON0002&empId=KECF00002&keyword=&page=")
//     .then((res)=>{
//         return res;
//     })
// }
 
//puplication
// export const PubliCationCreate=(data)=>{
//   console.log(data)
// return axios
//   .post(host+"/proficiency/publication",data)
//   .then((res)=>{
//     return res;
//   })
// }
 
// export const PubliCationUpdate=(data)=>{
//   return axios
//   .post(host+"/proficiency/publication",data)
//   .then((res)=>{
//      return res;
//   })
//  }
 
// export const PubliCationGetTable=(data)=>{
//  return axios
//  .get(host+"proficiency/get/publication?"+"empId="+data+"&keyword=&page=")
//  .then((res)=>{
//     return res;
//  })
// }
 
// export const PubliCationTableData=()=>{
//     return axios
//     .get(host+"/proficiency/get/publication?coId=ON0002&empId=KECF00002&keyword=&page=")
//     .then((res)=>{
//         return res;
//     })
// }
 
//patents
// export const patentsCreates=(data)=>{
//   console.log(data)
// return axios
//   .post(host+"/proficiency/patent",data)
//   .then((res)=>{
//     return res;
//   })
// }
 
// export const patentsUpdate=(data)=>{
//   return axios
//   .post(host+"/proficiency/patent",data)
//   .then((res)=>{
//      return res;
//   })
//  }
 
// export const patentsGetTable=(data)=>{
//  return axios
//  .get(host+"proficiency/get/patent?"+"empId="+data+"&keyword=&page=")
//  .then((res)=>{
//     return res;
//  })
// }
 
// export const patentsGetTableData=()=>{
//     return axios
//     .get(host+"/proficiency/get/patent?coId=ON0002&empId=KECF00002&keyword=&page=")
//     .then((res)=>{
//         return res;
//     })
// }
 
//trainings
export const trainingsCreate = (data) => {
  console.log(data);
  return axios.post(host + "proficiency/trainings", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const trainingsUpdate = (data) => {
  return axios.post(host + "proficiency/trainings", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const trainingsGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/trainings?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const trainingsGetTableData = (id) => {
  return axios
    .get(host + "proficiency/get/trainings?trainId=" + id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
// export const sponsoredGetTable=(data)=>{
//  return axios
//  .get(host+"proficiency/get/sponsoredResearch?"+"empId="+data+"&keyword=&page=")
//  .then((res)=>{
//     return res;
//  })
// }
 
// export const sponsoredGetTableData=()=>{
//     return axios
//     .get(host+"/proficiency/get/sponsoredResearch?coId=ON0002&empId=KECF00002&keyword=&page=")
//     .then((res)=>{
//         return res;
//     })
// }
//create employee
 
export const employeeCreate = (data) => {
  console.log(data);
  return axios.post(host + "employee/create/employee", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
// http://localhost:8080/employee/get/active?status=Active&keyword=&type=&designation=&department=&experience=
 
export const employeeGetTable = (data) => {
  return axios
    .get(
      host +
        "employee/get/active?status=" +
        data.status +
        "&keyword=" +
        data.keyword +
        "&type=" +
        data.type +
        "&designation=" +
        data.designation +
        "&department=" +
        data.department +
        "&experience=" +
        data.experience +
        "&page=" +
        data.page +
        "&sort=desc" ,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
export const employeeSingleData = (employeeId) => {
  return axios
    .get(host + "employee/get/employee/profile?employeeId=" + employeeId,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
export const employeedeleteSingleData = (data) => {
  return axios
    .delete(host + "employee/delete/employee?employeeId=" + data.employeeId,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
export const employeeupdateSingleData = (id) => {
  return (
    axios
      // .put(host+"employee/update/employee"+id)
      .get(host + "employee/getSingle/employeeDetails?employeeId=" + id,{ headers: getHeaders() })
      .then((res) => {
        return res;
      })
  );
};
export const employeeUpdate = (data) => {
  return (
    axios
      .put(host + "employee/update/employee", data,{ headers: getHeaders() })
      // .get(host+"employee/getSingle/employeeDetails?employeeId="+id)
      .then((res) => {
        return res;
      })
  );
};
// export const OnlineCourseGetTableData=(id)=>{
//   return axios
//   .get(host+"/proficiency/get/online/courses?coId="+id)
//   .then((res)=>{
//       return res;
//   })
// }
 
// export const employeeSingleData =() =>{
//   return axios
//   .get(host+"employee//get/employee/profile?employeeId=KEC2002",)
//   .then((res) =>{
//     return res;
//   })
// }
// export const employeUpdate=(data)=>{
//   return axios
//   .post(host+"/proficiency/sponsoredResearch",data)
//   .then((res)=>{
//      return res;
//   })
//  }
 
// export const employeeGetTable=(data)=>{
//  return axios
//  .get(host+"proficiency/get/sponsoredResearch?"+"empId="+data+"&keyword=&page=")
//  .then((res)=>{
//     return res;
//  })
// }
 
// export const employeeGetTableData=()=>{
//     return axios
//     .get(host+"/proficiency/get/sponsoredResearch?coId=ON0002&empId=KECF00002&keyword=&page=")
//     .then((res)=>{
//         return res;
//     })
// }
//puplication
export const PubliCationCreate = (data) => {
  console.log(data);
  return axios.post(host + "proficiency/publication", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const PubliCationUpdate = (data) => {
  return axios.post(host + "proficiency/publication", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const PubliCationGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/publication?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const PubliCationTableData = (id) => {
  return axios
    .get(host + "proficiency/get/publication?pubId=" + id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
//guidance
export const guidanceCreate = (data) => {
  console.log(data);
  return axios.post(host + "proficiency/guidance", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const guidanceUpdate = (data) => {
  return axios.post(host + "proficiency/guidance", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const guidanceGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/guidance?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const guidanceGetTableData = (id) => {
  return axios
    .get(host + "proficiency/get/guidance?guidId=" + id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
//honour
export const honourCreate = (data) => {
  console.log(data);
  return axios.post(host + "proficiency/honours/awards", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const honourUpdate = (data) => {
  return axios.post(host + "proficiency/honours/awards", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const honourGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/honours/awards?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const honourGetTableData = (id) => {
  return axios
    .get(host + "proficiency/get/honours/awards?honId=" + id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
//patents
export const patentsCreates = (data) => {
  console.log(data);
  return axios.post(host + "proficiency/patent", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const patentsUpdate = (data) => {
  return axios.post(host + "proficiency/patent", data,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
 
export const patentsGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/patent?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const patentsGetTableData = (id) => {
  return axios.get(host + "proficiency/get/patent?patId=" + id,{ headers: getHeaders() }).then((res) => {
    return res;
  });
};
//sponsored Research
export const sponsoredCreate = (data) => {
  console.log(data);
  return axios
    .post(host + "proficiency/sponsoredResearch", data,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
export const sponsoredUpdate = (data) => {
  return axios
    .post(host + "proficiency/sponsoredResearch", data,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
export const rejectCallProficiency = (payload) => {
  return axios
    .post(host + "proficiency/request/approval", payload,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
export const approveCallProficiency = (payload) => {
  return axios
    .post(host + "proficiency/request/approval", payload,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
export const imageCall = (imagePath) => {
  return axios.get(host + "employee/get/uploaded/file?fileName=" + imagePath,{ headers: getHeaders() });
};
export const sponsoredGetTable = (data) => {
  return axios
    .get(
      host +
        "proficiency/get/sponsoredResearch?" +
        "empId=" +
        data.empID +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sordId +
        "," +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const sponsoredGetTableData = (id) => {
  return axios
    .get(host + "proficiency/get/sponsoredResearch?sprId=" + id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
export const AppraisalGetTable = (data) => {
  return axios
    .get(
      host + "proficiency/get/appraisal?" + "empId=" + data.empID + "&keyword=",{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const AppraisalGetTableData = (data) => {
  return axios
    .get(host + "proficiency/get/appraisal?apId=" + data.id,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
export const AppraisalSingleCallPublication = (data) => {
  console.log(data, "rgiug");
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=publication",{ headers: getHeaders() }
  );
};
 
export const AppraisalSingleCallHonoursAndAwards = (data) => {
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=honoursAndAwards",{ headers: getHeaders() }
  );
};
 
export const AppraisalSingleCallpatent = (data) => {
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=patent",{ headers: getHeaders() }
  );
};
 
export const AppraisalSingleCallTraining = (data) => {
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=trainings",{ headers: getHeaders() }
  );
};
 
export const AppraisalSingleCallGuidance = (data) => {
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=guidance",{ headers: getHeaders() }
  );
};
 
export const AppraisalSingleCallsponsoredResearch = (data) => {
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=sponsoredResearch",{ headers: getHeaders() }
  );
};
 
export const AppraisalSingleCallonlinecourse = (data) => {
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=onlineCourses",{ headers: getHeaders() }
  );
};
 
export const AppraisalSingleCallperformance = (data) => {
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=performance",{ headers: getHeaders() }
  );
};
 
export const AppraisalSingleCallResponsibilities = (data) => {
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=responsibilities",{ headers: getHeaders() }
  );
};
 
export const AppraisalSingleCallBooksandChapter = (data) => {
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=booksAndChapter",{ headers: getHeaders() }
  );
};
 
export const AppraisalSingleCallConsultancy = (data) => {
  return axios.get(
    host + "proficiency/get/list?apId=" + data.id + "&keyword=consultancy",{ headers: getHeaders() }
  );
};
 
export const PrintSingleCall = (datas) => {
  return axios.get(
    host +
      "proficiency/print/appraisal?empId=" +
      datas.empId +
      "&academicYear=2024-2025",{ headers: getHeaders() }
  );
};
 
export const empIdCall = () => {
  return axios.get(host + "employee/get/allEmployeeIds?field=id&target=",{ headers: getHeaders() });
};
 
export const empDetails = (employeeId) => {
  return axios
    .get(host + "employee/getSingle/employeeDetails?employeeId=" + employeeId,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
export const facultyCall = (data) => {
  return axios.get(
    host + "employee/get/allEmployeeIds?field=name" + "&target=" + data,{ headers: getHeaders() }
  );
};
 
export const editHistory = (empId) => {
  return axios.get(
    host + "employee/EmployeeProfileHistory?employeeId=" + empId,{ headers: getHeaders() }
  );
};
 
export const AcademicYear = () => {
  return axios.get("https://qa.vinbytes.com/be-feetracker/fee/get/academic");
};
 
export const CheckDuplicateValues = (datas) => {
  return axios.post(
    host + "employee/isExist?value=" + datas.email + "=&field=email",{ headers: getHeaders() }
  );
};
 
export const TableSubmitCall = (payload) => {
  return axios
    .post(host + "proficiency/request/submit", payload,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};
 
// ,{ headers: headers }
 
export const ProficiencyTableCall = (data) => {
  return axios
    .get(
      host +
        "proficiency/proficiency/validation?status=" +
        data.status +
        "&keyword=" +
        data.keyword +
        "&page=" +
        data.page +
        "&sort=" +
        data.sort,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
 
export const ProficiencyValidationView = (data) => {
  return axios
    .get(
      host +
        "proficiency/proficiency/validation?section=" +
        data.section +
        "&sectionId=" +
        data.sectionId,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};
export const ProficiencyView = (data) => {
  return axios
    .get(
      host +
        "proficiency/proficiencyDetails?empId=" +
        data.id +
        "&academicYear=" +
        data.academicYear,{ headers: getHeaders() }
    )
    .then((res) => {
      return res;
    });
};


export const employeeDeactivate = (data) => {
  console.log('employeeId:', data.employeeId);
  return (
    axios
      // .delete(host + "employee/delete/employee?employeeId=" + data.employeeId)
      .delete(
        host +
          "employee/delete/employee?employeeId=" +
          data.employeeId +
          "&reason=" +
          data.reason +
          "&comments=" +
          data.comment,{ headers: getHeaders() }
      )
      .then((res) => {
        return res;
      })
  );
};
 
 
export const ProficiencyViewCall = (data) => {
  return axios
    .get(host + "proficiency/get/"+data.url+"="+data.item,{ headers: getHeaders() })
    .then((res) => {
      return res;
    });
};

export const employeeFileView =(imgPath)=>{
  return axios 
  .get(host+"employee/get/uploaded/file?fileName="+imgPath,{ headers: getHeaders() })
  .then((res)=>{
    return res ; 
  })
}